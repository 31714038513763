import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  details:any = {
    'cdrKey': '',
    'ani': '',
    'dnis': '',
    'portNum': ''
  }

  private callDetails = new BehaviorSubject<any>(this.details);
  currentcallDetails = this.callDetails.asObservable();

  private username = new BehaviorSubject('');
  currentUsername = this.username.asObservable();

  constructor() { }

  updateUsername(username:string){
    this.username.next(username);
  }

  updateCallDetails(callDetails:any){
    this.callDetails.next(callDetails);
  }


}
