<div class="container-fluid p-0" style="height: 100vh;">
    <div class="d-flex"
        [ngStyle]="{'height': '7%', 'background': '#fff'}">
    </div>
    <div class="d-flex custom-border-bottom align-items-center"
        [ngStyle]="{'height': '7%','background': '#fff'}">
        <h6 ngClass="page-heading m-0" [ngStyle]="{ 'padding': '0 6%'}">
            THANK YOU
        </h6>
    </div>
    <div class="container-fluid" style="padding: 0% 6%;">
	
		<div style="margin-top: 10%;">
			
			<h6>
				Thank you for scheduling Prescription Delivery with Springdale.
				<br/>Good Bye
			</h6>
			
		</div>
		
	</div>
    <div style="margin-top: 10%;">
			
        <h6 style="padding-left: 5%; height:30px; color: #FFFFFF; font-size: 16; font-weight: bold; background-color: #DC291E;">
            
        </h6>
        
    </div>
</div>