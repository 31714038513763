import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';

@Component({
  selector: 'app-process-payment',
  templateUrl: './process-payment.component.html',
  styleUrls: ['./process-payment.component.css']
})
export class ProcessPaymentComponent implements OnInit {

  callDetails:any;
  username:string;

  constructor(
    private rxStompService:RxStompService,
    private dataService:SharedDataService
  ) { }

  ngOnInit(): void {
    console.log('ProcessPayment Initialized......')
    this.dataService.currentcallDetails.subscribe(details => this.callDetails = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.callDetails,'ProcessPayment','startProcessPayment', 'startProcessPayment', 'SCEDULED', this.username, '');
  }

}
