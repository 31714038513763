<div style="margin-top: 40%; height: 100vh">
		
    <div class="d-flex flex-column text-center">
      
      <div class="fs-3 mb-3" style="font-weight: bold;">
       Thank You!
      </div>
      
      <div style="color: #AA6600;">
       
        Thank you for calling Springdale Pharmacy.
      
      </div>

    </div>

</div>