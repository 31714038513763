<style>

  /* Responsive Styles */
  @media screen and (max-width: 767px) {
   
  }

  @media screen and (max-width: 575px) {
    
  }
</style>
<app-header class="fixed-top" style="height: 7%;"></app-header>
<!-- <app-loader></app-loader> -->
<!-- <app-login></app-login> -->
<!-- <app-payemnt-details></app-payemnt-details> -->
<!-- <app-delivery-details></app-delivery-details> -->
<!-- <inputCard></inputCard> -->
<router-outlet></router-outlet>
