import { Injectable, OnInit } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { CHAT_BROKER, CHAT_TOPIC } from '../constants/global';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.watch(CHAT_TOPIC).subscribe((message: any) => {
      console.log('Module name received as [' + message.body + "]");	
    });
  }

  onSendMessage(callDetails:object, moduleName:string, moduleMessage:string, uiActivity:string, uiResponse:string, username:string, uuid:string) {
    const message = `Message generated at ${new Date()}`;
    this.publish({ destination: CHAT_BROKER, 
      body: JSON.stringify({
        callDetails: callDetails,
        moduleName: moduleName,
        moduleMessage: moduleMessage,
        uiActivity: uiActivity,
        uiResponse: uiResponse,
        username: username,
        uuid: uuid
      })
    });
  }

}


