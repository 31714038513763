import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'inputCardExpiry',
  templateUrl: './input-card-expiry.component.html',
  styleUrls: ['./input-card-expiry.component.css'],
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => InputCardExpiryComponent),
       multi: true
    }
  ]
})
export class InputCardExpiryComponent implements OnInit, ControlValueAccessor {

  private _value:string;

  get value(){
    return this._value
  }

  set value(v:string){
    if(v!=this._value){
      this._value = v;
      this.onChange(v);
    }
  }

  constructor() { }

  onChange(v:string)  {

  }

  onTouch() {

  }

  writeValue(obj: any): void {
    this._value = obj;
    // throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
    // throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  onNumberEnter(event:any){
    // console.log(this.cardNumber)
    let excludedKeys = [8, 37, 39, 46, 9, 13, 110, 190];
    const keyCode = event.keyCode;
    const value = event.key;
    if(!((keyCode>=48 && keyCode<=57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))){
      event.preventDefault()
    }
    
    if(this._value != undefined && event.key != 'Backspace' && this._value.trim().length == 2){
      // if(this._value.replaceAll(this.separator,'').length<this.length)
      this._value = this._value + '/';
    }
    // console.log(value)
  }

  onBackspace(event:any){
    if(event.key == 'Backspace' && this._value != undefined && this._value.endsWith('/')){
        // console.log("26|"+this.cardNumber+"|", this.cardNumber.length,this.cardNumber.endsWith(this.separator))
        this._value = this._value.replace('/','');
    }
  }

}
