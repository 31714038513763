<div class="container-fluid p-0" style="height: 100vh;">
    <div class="d-flex"
        [ngStyle]="{'height': '7%', 'background': '#fff'}">
    </div>
    <div class="d-flex custom-border-bottom align-items-center"
        [ngStyle]="{'height': '7%','background': '#fff'}">
        <h6 ngClass="page-heading m-0" [ngStyle]="{ 'padding': '0 6%'}">
            MAKE PAYMENT
        </h6>
    </div>
    <div>
		<div style="background-color: #f2f6f9;">
			<table class="table table-hover">
				<tbody>
				  <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem;">Name</td>
			        <td style="font-weight: 400;">James Swan</td><!--  -->
			      </tr>
			      <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem;">DOB</td>
			        <td style="font-weight: 400;">03/12/1997</td><!--  -->
			      </tr>
			      <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem;">Prescription</td>
			        <td style="font-weight: 400;">Janumet 100mg</td><!--  -->
			      </tr>
			      <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem;">Phone</td>
			        <td style="font-weight: 400;">88888888</td><!--  -->
			      </tr>
			      <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem; font-weight: bold;">Amount due</td>
			        <td style="font-weight: bold;">
			        	{{'$100.00'}}
			        </td>
			      </tr>
			    </tbody>
			</table>
		</div>
	</div>
    <div class="" style="padding: 0% 6%;">
        <form #paymentForm="ngForm" class="row" (ngSubmit)="onPaymentSubmit(paymentForm)">
            <div class="mb-3">
                <label for="dob" class="form-label">Payment Date</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="paymentDate" [minDate]="minDate" [(ngModel)]="paymentDate" ngbDatepicker
                        #d="ngbDatepicker" />
                    <button class="btn btn-danger" (click)="d.toggle()"
                        type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="my-4">
                <label for="phoneNo" class="form-label">Payment Option</label>
                <div class="btn-group col-12" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check " name="btnradio" id="btnradio1" autocomplete="off" checked>
                    <label class="btn payment-type-btn" for="btnradio1">New credit card</label>
                  
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                    <label class="btn payment-type-btn" for="btnradio2">New debit card</label>
                </div>
            </div>
            <div class="mb-3">
                <!-- <input class="form-control" placeholder="Card number" name="cardNumber" [(ngModel)]="cardNumber"/> -->
                <inputCard name="cardNumber" [(ngModel)]="cardNumber" [length]="16" separator=" " ></inputCard>
                <span class="errMessage" *ngIf="cardNumberError">Please Enter a Valid Card Number.</span>
            </div>
            <div class="d-flex align-items-baseline justify-content-between">
                <div class="mb-3 col-6">
                    <!-- <input class="form-control col-3" placeholder="MM/YY" name="expiryDate" [(ngModel)]="expiryDate"/> -->
                    <inputCardExpiry class="mb-3 col-6" name="expiryDate" [(ngModel)]="expiryDate"></inputCardExpiry>
                    <span class="errMessage" *ngIf="noExpiraryDetails">Please enter card expiration details.</span>
                    <span class="errMessage" *ngIf="invalidExpiraryMonth">Please enter a valid card expiration month.</span>
                    <span class="errMessage" *ngIf="invalidExpiraryYear">Please enter a valid card expiration year.</span>
                </div>
                <div class="mb-3 col-4">
                    <input class="form-control col-3" maxlength="3" placeholder="CVV" name="cvv" [(ngModel)]="cvv" (keydown)="onNumberEnter($event)"/>
                    <span class="errMessage" *ngIf="cardCVVError">Please enter a valid CVV.</span>
                </div>
                <div>
                    <ng-template #popTitle><b>The CVV number is the last three digits at the back of your card.</b></ng-template>
                    <ng-template #popContent>For <strong>American Express</strong> cards, the CVV is a 4 digit number on the front of the card. </ng-template>
                    <button type="button" style="background: transparent;border: none;outline: 0;" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                        <fa-icon [icon]="infoIcon" style="opacity: 0.5;"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="col-12" style="margin-top: 5%;margin-bottom: 2%;">
                <label class="container-checkbox"> 
                 <input type="checkbox" data-ng-model="savePaymentMethod">
                 <span class="checkmark" style="border-radius: 5px;margin-top: 0.5%;"></span>
               </label>
               <span style="margin-left: 9%;">Save payment method</span>
            </div>
            
            <div class="my-5">
                <div class="d-grid">
                    <div class="btn-group-nbc d-flex justify-content-between">
                        <button type="submit" class="btn btn-next">PAY</button>
                        <button type="button" class="btn btn-back" (click)="goBackToLogin()">BACK</button>
                        <button type="button" class="btn btn-cancel" (click)="onCancel()">CANCEL</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>