/** Websocket (Stomp) properties **/
export const BACKEND_IP = '66.241.101.195';
export const BACKEND_PORT = '9092';
export const APP_NAME = 'PrescribeWellness';
export const BASE_URL = 'http://' + BACKEND_IP + ':' + BACKEND_PORT + '/' + APP_NAME;
export const SOCKET_URL = "/chat";
export const CHAT_TOPIC = "/user/queue/msg";
export const CHAT_BROKER = "/application/chat";

/** http properties **/
export const linkOpened = '/link-open'


/** Routes */
// const MainRoute="/"+APP_NAME					
export const LoaderRoute="/"+APP_NAME+"/loading"		
export const LoginRoute="/"+APP_NAME+"/login"		
export const PayemntDetailsRoute="/"+APP_NAME+"/payment-details"	
export const DeliveryDetailsRoute="/"+APP_NAME+"/delivery-details"	
export const ProcessPaymentRoute="/"+APP_NAME+"/process-payment"
export const ThankyouRoute="/"+APP_NAME+"/thankyou"	
