<div class="container-fluid p-0" style="height: 100vh;">
    <div class="d-flex"
        [ngStyle]="{'height': '7%', 'background': '#fff'}">
    </div>
    <div class="d-flex custom-border-bottom align-items-center"
        [ngStyle]="{'height': '7%','background': '#fff'}">
        <h6 ngClass="page-heading m-0" [ngStyle]="{ 'padding': '0 6%'}">
            HOME DELIVERY
        </h6>
    </div>
    <div>
		<div style="background-color: #f2f6f9;">
			<table class="table table-hover">
				<tbody>
			      <tr>
			        <td style="padding-left: 6%; padding-top: 0.75rem; padding-bottom: 0.75rem;">Prescription</td>
			        <td style="font-weight: 400;">Janumet 100mg</td><!--  -->
			      </tr>
			    </tbody>
			</table>
		</div>
	</div>
    <div class="" style="padding: 2% 6%;">
        <form class="row" name="deliveryDetailsForm1" #deliveryDetailsForm="ngForm" (ngSubmit)="onDeliverySubmit(deliveryDetailsForm)">
            <label for="dob" class="form-label">Schedule Delivery</label>
            <div class="mb-3">
                <label for="dob" class="form-label">Date</label>
                <div class="input-group">
                    <input class="form-control" name="datepicker" placeholder="yyyy-mm-dd" name="dp" [minDate]="minDate" [(ngModel)]="model" ngbDatepicker
                        #d="ngbDatepicker" />
                    <button class="btn btn-danger" (click)="d.toggle()"
                        type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label for="dob" class="form-label">Time</label>
                <div class="input-group">
                    <ngb-timepicker name="timepicker" [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
                </div>
            </div>
            <div class="mb-3">
                <label for="dob" class="form-label">Address</label>
                <textarea class="form-control" placeholder="Address" name="cardNumber"></textarea>
            </div>
            <div class="col-12" style="margin-top: 5%;margin-bottom: 2%;">
                <label class="container-checkbox"> 
                 <input type="checkbox" data-ng-model="savePaymentMethod">
                 <span class="checkmark" style="border-radius: 5px;margin-top: 0.5%;"></span>
                </label>
                <span style="margin-left: 9%;">Notify</span>
            </div>
            
            <div class="my-5">
                <div class="d-grid">
                    <div class="btn-group-nbc d-flex justify-content-between">
                        <button type="submit" class="btn btn-next">SCHEDULE</button>
                        <button type="button" class="btn btn-back" (click)="goBackToPaymentDetails()">BACK</button>
                        <button type="button" class="btn btn-cancel" (click)="onCancel()">CANCEL</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>