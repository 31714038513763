import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { PayemntDetailsComponent } from './components/payemnt-details/payemnt-details.component';
import { ProcessPaymentComponent } from './components/process-payment/process-payment.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { LoaderComponent } from './shared/components/loader/loader.component';

const routes: Routes = [
  {path:"PrescribeWellness", component:MainComponent},
  {path:"PrescribeWellness/loading", component:LoaderComponent},
  {path:"PrescribeWellness/login", component:LoginComponent},  //login/:username
  {path:"PrescribeWellness/payment-details", component:PayemntDetailsComponent},
  {path:"PrescribeWellness/delivery-details", component:DeliveryDetailsComponent},
  {path:"PrescribeWellness/process-payment", component:ProcessPaymentComponent},
  {path:"PrescribeWellness/thankyou", component:ThankyouComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
