import { Component, OnInit } from '@angular/core';
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoaderRoute } from 'src/app/constants/global';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  dob:NgbDateStruct;
  phone:'';
  phoneNAErr:boolean = false
  phoneInvalid:boolean =false
  dobNAErr:boolean =false
  dobInvalid:boolean =false

  minDate:any = {
    year: Number(moment().subtract(130, 'years').format('YYYY')), 
    month: Number(moment().format('M')), 
    day: Number(moment().format('D'))
  }
  maxDate:any = {
    year: Number(moment().format('YYYY')), 
    month: Number(moment().format('M')), 
    day: Number(moment().format('D'))
  }

  constructor(
    private rxStompService:RxStompService,
    private dataService:SharedDataService,
    private router:Router,
    ) { 

  }

  callDetails:any;
  username:string;
  ngOnInit(): void {
    console.log('Login Initialized......', this.maxDate)
    this.dataService.currentcallDetails.subscribe(details => this.callDetails = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
  }

  onLoginSubmit(loginForm:any) {

    console.log('onLoginSubmit')

    console.log('phone: ', this.phone)
    console.log('dob: ', this.dob)

    if(this.phone == undefined || this.phone.trim().length == 0){
      this.phoneNAErr = true;
    }else{
      this.phoneNAErr = false;
    }
    if(this.dob == undefined || this.dob.toString().trim().length == 0){
      this.dobNAErr = true;
    }else{
      this.dobNAErr = false;
    }
    if(!this.phoneNAErr && !this.dobNAErr){
      this.router.navigateByUrl(LoaderRoute);
      this.rxStompService.onSendMessage(this.callDetails,'MemberId','MemberId', 'SUCCESS', 'SUCCESS', this.username, '');
    }else{
      this.rxStompService.onSendMessage(this.callDetails,'MemberId','invalidMemberId', 'error', 'ERROR1', this.username, '');
    }
  }

}
