import { Component, OnInit } from '@angular/core';
import { NgbAlertModule, NgbDatepickerModule, NgbTimepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Router } from '@angular/router';
import { LoaderRoute } from 'src/app/constants/global';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.css']
})
export class DeliveryDetailsComponent implements OnInit {

  callDetails:any;
  username:string;

  model:NgbDateStruct;
	meridian = true;

  minDate:any = {
    year: Number(moment().format('YYYY')), 
    month: Number(moment().format('M')), 
    day: Number(moment().format('D'))
  }
  time = { 
    hour: moment().hour(), 
    minute: moment().minute() 
  };

  constructor(
    private rxStompService:RxStompService,
    private dataService:SharedDataService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    console.log('DeliveryDetails Initialized......')
    this.dataService.currentcallDetails.subscribe(details => this.callDetails = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.callDetails,'EnterDeliveryDetails','startDeliveryDetails', 'startEnterDeliveryDetails', 'SUCCESS', this.username, '');
  }

  onDeliverySubmit(deliverForm:any){
    console.log('onDeliverySubmit')
    this.router.navigateByUrl(LoaderRoute);
    this.rxStompService.onSendMessage(this.callDetails,'EnterDeliveryDetails','finishEnterDeliveryDetails', 'finishDeliveryDetails', 'SUCCESS', this.username, '');
  }

  goBackToPaymentDetails(){
    console.log('goBackToPaymentDetails')
    this.router.navigateByUrl(LoaderRoute);
    this.rxStompService.onSendMessage(this.callDetails,'UserEvent','startBackPress', 'startBackPress', 'BackPress', this.username, '');
  }

  onCancel(){
    console.log('callEnd')
    this.rxStompService.onSendMessage(this.callDetails,'UserEvent','startCallEnd', 'startCallEnd', 'CallEnd', this.username, '');
  }

}
