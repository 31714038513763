import { RxStompConfig } from '@stomp/rx-stomp';
import { APP_NAME, BACKEND_IP, BACKEND_PORT, SOCKET_URL } from '../constants/global';

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  // brokerURL: BASE_URL + CHAT_BROKER,
  // brokerURL: 'ws://localhost:9096/PrescribeWellnessHEB/chat/websocket', // added suffix /websocket for SockJs
  brokerURL: 'ws://' + BACKEND_IP + ':' + BACKEND_PORT + '/' + APP_NAME + SOCKET_URL + '/websocket', // added suffix /websocket for SockJs

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'guest',
    passcode: 'guest',
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.log(new Date(), msg);
  },
};