import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BASE_URL, CHAT_TOPIC, DeliveryDetailsRoute, linkOpened, LoaderRoute, ProcessPaymentRoute, ThankyouRoute } from 'src/app/constants/global';
import { HttpService } from 'src/app/shared/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  
  username:string;
  cdrKey: string;
  callDetails: object;
  visualIVRData: any;

  constructor(
    private httpService: HttpService, 
    private activatedRoute: ActivatedRoute, 
    private rxStompService:RxStompService,
    private router:Router,
    private dataService:SharedDataService
    ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params['username']);
      this.username = params['username']
    });

    if (this.username == undefined) {
      this.username = 'linsys4d-PORT2-0225201918411498--html';
		  this.cdrKey = this.username.split("--")[0];
    } else {
      this.cdrKey = this.username.split('--')[0];
    }

    this.callDetails = {
      'cdrKey': this.cdrKey,
      'ani': '',
      'dnis': '',
      'portNum': ''
    }
    this.dataService.updateCallDetails(this.callDetails);
    this.dataService.updateUsername(this.username);

    this.rxStompService.watch(CHAT_TOPIC).subscribe((message) => {
      
      console.log('Module name received as [' + JSON.parse(message.body).moduleName + "]");	
      this.visualIVRData = JSON.parse(message.body);

      if(this.visualIVRData.moduleName === 'MemberId') {
        console.log('HEBVIVR: Main controller: CallDeatils: ', this.callDetails);
        this.router.navigateByUrl(LoaderRoute);
      } else if(this.visualIVRData.moduleName === 'EnterPaymentDetails') {
        console.log('HEBVIVR: Main Controller: CallDeatils: ', this.callDetails);
        this.router.navigateByUrl(ProcessPaymentRoute);
      } else if(this.visualIVRData.moduleName === 'EnterDeliveryDetails') {
        console.log('HEBVIVR: Main Controller: CallDeatils: ', this.callDetails);
        this.router.navigateByUrl(DeliveryDetailsRoute);
      } else if(this.visualIVRData.moduleName === 'ProcessPayment') {
        console.log('HEBVIVR: Main Controller: CallDeatils: ', this.callDetails);
        this.router.navigateByUrl(ProcessPaymentRoute);
      } else if(this.visualIVRData.moduleName === 'ThankYou') {
        console.log('HEBVIVR: Main Controller: CallDeatils: ', this.callDetails);
        this.router.navigateByUrl(ThankyouRoute);
      }
    });

    // setTimeout(() => {
    //   this.rxStompService.onSendMessage(this.callDetails,'REGISTER','REGISTER', 'REGISTER', 'REGISTER', this.username, '')
    // }, 2000);

    this.rxStompService.onSendMessage(this.callDetails,'REGISTER','REGISTER', 'REGISTER', 'REGISTER', this.username, '')
    this.notifyLinkOpened()

  }


  
  notifyLinkOpened() {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Content-Type': 'text',
    })

    const options = {
      headers: httpHeaders,
    };

    this.httpService.post(BASE_URL + linkOpened, {username: this.username, callDetails: this.callDetails}, options).subscribe(
      result => {
        console.log('MainController : Result [' + result + ']')
        this.router.navigateByUrl(LoaderRoute);
      },
      error => {  
        console.error(error.message || error);
      }
    )

  }

}
