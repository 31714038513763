import { Component, OnInit } from '@angular/core';
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { RxStompService } from 'src/app/stomp/rx-stomp.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoaderRoute } from 'src/app/constants/global';

@Component({
  selector: 'app-payemnt-details',
  templateUrl: './payemnt-details.component.html',
  styleUrls: ['./payemnt-details.component.css']
})
export class PayemntDetailsComponent implements OnInit {

  paymentDate:NgbDateStruct;
  infoIcon=faCircleQuestion

  cardNumber:string;
  expiryDate:string;
  cvv:string;

  callDetails:any;
  username:string;

  cardNumberError:boolean = false;
  noExpiraryDetails:boolean = false;
  invalidExpiry:boolean = false;
  invalidExpiraryMonth:boolean = false;
  invalidMonth:boolean = false;
  invalidExpiraryYear:boolean = false;
  invalidYear:boolean = false;
  cardCVVError:boolean = false;
  minDate:any = {
    year: Number(moment().format('YYYY')), 
    month: Number(moment().format('M')), 
    day: Number(moment().format('D'))
  }

  constructor(
    private rxStompService:RxStompService,
    private dataService:SharedDataService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    console.log('PaymentDetails Initialized......')
    this.dataService.currentcallDetails.subscribe(details => this.callDetails = details);
    this.dataService.currentUsername.subscribe(username => this.username = username);
    this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','startPaymentDetails', 'startEnterPaymentDetails', 'SUCCESS', this.username, '');
  }

  onPaymentSubmit(paymentForm:any){
    console.log('onPaymentSubmit')
    console.log('cardNumber', this.cardNumber)

    // if(this.cardNumber === undefined || this.cardNumber == null || this.cardNumber.toString() === '' || isNaN(parseInt(this.cardNumber.toString().replaceAll(' ','')))){
    //   this.cardNumberError = true;
    // }else{
    //   var tempCardNumber = this.cardNumber.replaceAll(' ','');
    //   console.log('tempCardNumber', tempCardNumber.toString().length)
    //   if(tempCardNumber.toString().length > 11 && tempCardNumber.toString().length <= 16){
    //     this.cardNumberError = false;
    //   }else{
    //     this.cardNumberError = true;
    //   }
    // }

    if(!this.cardNumberError && !this.noExpiraryDetails && !this.invalidExpiry && !this.invalidYear && !this.invalidMonth && !this.cardCVVError){
      this.router.navigateByUrl(LoaderRoute);
      this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','finishEnterPaymentDetails', 'finishPaymentDetails', 'SUCCESS', this.username, '');
    }else{

      if(this.cardNumberError){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'InvalidCardNumber', 'HorizonE05', this.username, '');
      }
      else if(this.noExpiraryDetails){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'NoExpirationDetails', 'HorizonE22', this.username, '');
      }
      else if(this.invalidExpiry){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'InvalidExpirationDetails', 'HorizonE20', this.username, '');
      }
      else if(this.invalidMonth){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'InvalidExpirationMonth', 'HorizonE08', this.username, '');
      }
      else if(this.invalidYear){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'InvalidExpirationYear', 'HorizonE21', this.username, '');
      }
      else if(this.cardCVVError){
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'InvalidCardCVV', 'HorizonE10', this.username, '');
      }else{
        this.rxStompService.onSendMessage(this.callDetails,'EnterPaymentDetails','errorEnterPaymentDetails', 'UnknownError', 'HorizonE11', this.username, '');
      }
    }
		
  }

  goBackToLogin(){
    console.log('goBackToLogin')
    this.router.navigateByUrl(LoaderRoute);
    this.rxStompService.onSendMessage(this.callDetails,'UserEvent','startBackPress', 'startBackPress', 'BackPress', this.username, '');
  }

  onCancel(){
    console.log('callEnd')
    this.rxStompService.onSendMessage(this.callDetails,'UserEvent','startCallEnd', 'startCallEnd', 'CallEnd', this.username, '');
  }

  onNumberEnter(event:any){
    let excludedKeys = [8, 37, 39, 46, 9, 13, 110, 190];
    const keyCode = event.keyCode;
    const value = event.key;
    if(!((keyCode>=48 && keyCode<=57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))){
      event.preventDefault()
    }
  }

}
