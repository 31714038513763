<div style="background-color: #ecf1f5; height: 40vh; margin-top: 20%;">
    <div class="container">
        <div class="row">
            <div style="width: 100%;">
                
                <label style="margin-top: 5%;color:#28547C;font-weight: 500;padding-left: 6%;font-family: Arial;">Privacy</label>
                <label style="margin-top: 5%;color:#28547C;font-weight: 500;padding-left: 7%;font-family: Arial;">Contact us</label>
                <label style="margin-top: 5%;color:#28547C;font-weight: 500;padding-left: 7%;font-family: Arial;">Terms</label>
                <label style="margin-top: 5%;color:#28547C;font-weight: 500;padding-left: 7%;font-family: Arial;">Help</label>
            
            </div>
            
            <div class="col-12" style="margin-top: 2%;padding-left: 6%;">
                <label style="font-family: Arial;letter-spacing: 0.03em;font-size: 0.9rem;opacity: 0.5;">
                    @Copyright Springdale Pharmacy Delivery Service. 2023
                </label>
            </div>
        </div>
    </div>
</div>