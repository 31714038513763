<div class="container-fluid p-0" style="height: 100vh;">
    <div class="d-flex"
        [ngStyle]="{'height': '7%', 'background': '#fff'}">
    </div>
    <div class="d-flex custom-border-bottom align-items-center"
        [ngStyle]="{'height': '7%','background': '#fff'}">
        <h6 ngClass="page-heading m-0" [ngStyle]="{ 'padding': '0 6%'}">
            LOG IN TO YOUR ACCOUNT
        </h6>
    </div>
    <div class="custom-border-bottom" style="padding: 6%;">
        <form #loginForm="ngForm" (ngSubmit)="onLoginSubmit(loginForm)">
            <div class="mb-3">
                <label for="phoneNo" class="form-label">Phone Number</label>
                <input type="tel" class="form-control" id="phone" name="phone" [(ngModel)]="phone" placeholder="" required>
                <span class="errMessage" *ngIf="phoneNAErr">Please enter a valid phone number.</span>
            </div>
            <div class="mb-3">
                <label for="dob" class="form-label">Date Of Birth</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dob" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="dob" ngbDatepicker
                        #d="ngbDatepicker" />
                    <button class="btn btn-danger" (click)="d.toggle()"
                        type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                        </svg>
                    </button>
                </div>
                <span class="errMessage" *ngIf="dobNAErr">Please select a valid date of birth.</span>
            </div>
            <div class="mt-5">
                <div class="d-grid">
                    <button type="submit" class="btn btn-block btn-danger btn-login">
                        LOG IN
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<app-footer></app-footer>