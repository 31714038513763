import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './shared/components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { PayemntDetailsComponent } from './components/payemnt-details/payemnt-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeliveryDetailsComponent } from './components/delivery-details/delivery-details.component';
import { RxStompService } from './stomp/rx-stomp.service';
import { rxStompServiceFactory } from './stomp/stomp-service-factory';
import { MainComponent } from './components/main/main.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { InputCardExpiryComponent } from './shared/ui/input-card-expiry/input-card-expiry.component';
import { InputCardComponent } from './shared/ui/input-card/input-card.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { ProcessPaymentComponent } from './components/process-payment/process-payment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    PayemntDetailsComponent,
    DeliveryDetailsComponent,
    MainComponent,
    LoaderComponent,
    InputCardComponent,
    InputCardExpiryComponent,
    ThankyouComponent,
    ProcessPaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
  ],
  providers: [ {
    provide: RxStompService,
    useFactory: rxStompServiceFactory,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
